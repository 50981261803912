<template lang="html">
  <div class="form">
    <div class="form__heading">
      <Heading text="Zahlungsart" :hType="2" textAlign="center" />
    </div>
    <div class="form__donate">
      <YourContribution :contributionValue="donateValue" />
    </div>

    <SelectPayment :isSubmit="isSubmit" />

    <div class="form__buttons">
      <div class="form__button">
        <Button @click="chooseMethod">
          <button type="button" v-if="globalSettings">
            {{ globalSettings['button_names']['pay'] }}
          </button>
        </Button>
      </div>

      <div class="form__button">
        <Button styleButton="border" @click="comeBack">
          <span v-if="globalSettings">
            {{ globalSettings['button_names']['back_button'] }}</span
          >
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import Heading from '@/components/Heading/Heading.vue';
import YourContribution from '@/components/YourContribution/YourContribution';
import SelectPayment from '../SelectPayment/SelectPayment';

export default {
  name: 'FormPaymentMethod',
  components: {
    SelectPayment,
    YourContribution,
    Button,
    Heading,
  },
  data() {
    return {
      isSubmit: false,
    };
  },
  props: {
    donateValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    chooseMethod() {
      this.$emit('chooseMethod');
      this.isSubmit = true;
      this.$nextTick(() => {
        this.isSubmit = false;
      });
    },
    comeBack() {
      this.$emit('comeBack');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
